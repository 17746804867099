<template>
  <div class="mainArea">
    <div class="contentCenter">
      <a href="https://fomoartspace.ch/docs/shape_shifters_fomo.pdf" class="pdflink" target="_blank"><b>Orlando Marosini, Adam Thompson</b> - Shape Shifters</a><br>
      <a href="https://fomoartspace.ch/docs/aline_witschi_angelos_merges_fomo.pdf" class="pdflink" target="_blank"><b>Aline Witschi & Angelos Merges</b> - The Radiance of Earthen Bonds</a><br>
      <a href="https://fomoartspace.ch/docs/lark_ring_fomo.pdf" class="pdflink" target="_blank"><b>Lark Ring</b> - Hearts</a><br>
      <a href="https://fomoartspace.ch/docs/wide_angle_framework_fomo.pdf" class="pdflink" target="_blank"><b>Irem Güngez, Oliver Kümmerli, Ramona Gschwend, Samir Seghrouchni, Sebastian Lendenmann</b> - Wide-Angle Framework - Curated By Vivianne Tat</a><br>
      <a href="https://fomoartspace.ch/docs/jorim_huber_fomo.pdf" class="pdflink" target="_blank"><b>Jorim Huber</b> - Seeing Something As Something</a><br>
      <h6><b>Lea & Felix Meier</b> - C-Conversations</h6>
      <a href="https://fomoartspace.ch/docs/small_talk_fomo.pdf" class="pdflink" target="_blank"><b>Maya Lama & Juris Strangots</b> - Small Talk</a><br>
      <a href="https://fomoartspace.ch/docs/martin_andereggen_fomo.pdf" class="pdflink" target="_blank"><b>Martin Andereggen</b> - When In The Moloch, S T F U</a><br>
      <a href="https://fomoartspace.ch/docs/visarte_fomo.pdf" class="pdflink" target="_blank"><b>FOMO Art Space Goes Visarte</b> - Flavia Trachsler, Ricardo Meli, Lark Ring, Angelos Merges, Leandra Agazzi - Curated by Leandra Agazzi</a><br>
      <a href="https://fomoartspace.ch/docs/arthur_heck_fomo.pdf" class="pdflink" target="_blank"><b>Arthur Heck</b> - The World Is On Fire and I’m in My Bed Burning For You - Curated by Arthur Heck & Leandra Agazzi</a><br>
      <a href="https://fomoartspace.ch/fomo_limited_edition_catalogue.pdf" class="pdflink" target="_blank"><b>FOMO Limited Edition</b> - Limited Poster Edition with 14 Artists</a><br>
      <h6><b>Kollektiv Sennhausbach</b> - Masche N°1: Fency</h6>
      <h6><b>Tom Tom</b> - Paradise Lost</h6>
      <a href="https://fomoartspace.ch/docs/friendly_space_fomo.pdf" class="pdflink" target="_blank"><b>Friendly Space</b> - Groupshow with 6 Artists - Curated by Miran Blažek & Leandra Agazzi</a><br>
      <a href="https://fomoartspace.ch/docs/stick_n_poke_fomo.pdf" class="pdflink" target="_blank"><b>Stick N Poke</b> - Groupshow with 20 Artists - Curated by Alana Alireza & Geraldine Belmont</a><br>
      <h6><b>Natalia Mimran</b> - Resurrection for All - Curated by Geraldine Belmnont</h6>
      <h6><b>Best of Offspace Flüelastrasse</b> - Groupshow with 32 Artists - Curated by Leandra Agazzi</h6>
      <h6><b>Linus Weber</b> - Situation 2 (Animated)</h6>
      <h6><b>Kata</b> - Wer hat den grössten</h6>
      <h6><b>Marc Kämpfen</b> - Loci</h6>
      <a href="https://fomoartspace.ch/docs/nico_sebastian_meyer_offspace_fluela.pdf" class="pdflink" target="_blank"><b>Nico Sebastian Meyer</b> - Buildings and Ruins</a><br>
      <a href="https://fomoartspace.ch/docs/leandra_agazzi_offspace_fluela.pdf" class="pdflink" target="_blank"><b>Leandra Agazzi</b> - Critters Lurking</a><br>
      <a href="https://fomoartspace.ch/docs/carla_schleiffer_offspace_fluela.pdf" class="pdflink" target="_blank"><b>Carla Schleiffer</b> - PS: I Know You Haven’t Forgotten</a><br>
      <a href="https://fomoartspace.ch/docs/shifting_coordinates_offspace_fluela.pdf" class="pdflink" target="_blank"><b>Denise Maud, Denisa Svachova, Alisha Dutt Islam</b> - Shifting Coordinates</a><br>
      <a href="https://fomoartspace.ch/docs/zaccheo_zilioli_offspace_fluela.pdf" class="pdflink" target="_blank"><b>Zaccheo Zilioli</b> - Various Systems</a><br>
      <a href="https://fomoartspace.ch/docs/flavia_trachsler_offspace_fluela.pdf" class="pdflink" target="_blank"><b>Flavia Trachsler</b> - Oblique</a><br>
      <a href="https://fomoartspace.ch/docs/rachel_tonthat_offspace_fluela.pdf" class="pdflink" target="_blank"><b>Rachel Tonthat</b> - Unhewn</a><br>
      <a href="https://fomoartspace.ch/docs/kesang_lamdark_offspace_fluela.pdf" class="pdflink" target="_blank"><b>Kesang Lamdark</b> - Plastic Passion is a Sold Out Scandal</a><br>
      <a href="https://fomoartspace.ch/docs/happy_sugar_live_offspace_fluela.pdf" class="pdflink" target="_blank"><b>Amanda del Valle, Gaia Del Santo, Anais Orr, Jasmin Irmi</b> - Happy Sugar Live - Curated by Saskia Sutter</a><br>
      <a href="https://fomoartspace.ch/docs/paint_offspace_fluela.pdf" class="pdflink" target="_blank"><b>Andreas Niederhauser & Nicolas Bischof</b> - Paint Abs, Paint Fig, Paint</a><br>
      <a href="https://fomoartspace.ch/docs/max_gisel_offspace_fluela.pdf" class="pdflink" target="_blank"><b>Max Gisel</b> - Building the Basement</a><br>
      <a href="https://fomoartspace.ch/docs/golfclayderman_offspace_fluela.pdf" class="pdflink" target="_blank"><b>GolfClayderman</b> - Max Chill</a><br>
      <a href="https://fomoartspace.ch/docs/ivana_jurisic_offspace_fluela.pdf" class="pdflink" target="_blank"><b>Ivana Jurisic</b> - A Time to Begin the End of a Time</a><br>
    </div>
  </div>
</template>
<script>
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
  mounted() {
    document.addEventListener("DOMContentLoaded", function() {
       
       if(/Android|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
       
           const linkies = document.getElementsByClassName('pdflink');
         for (const linky of linkies) {
           linky.href = "https://docs.google.com/viewerng/viewer?url="+linky.href;
       
         }
       
       }
       })
  },
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
    openInNewTab(url) {
      window.open(url, '_blank', 'noreferrer');
    },
  };
</script>
<style lang="scss">
</style>
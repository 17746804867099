<template>
  <div class="mainArea">
    <div class="contentCenter">
      <h1 class="underline">SCHELLINGER ZAUGG</h1>
      <h1>THE DREAM</h1>
      <h1>ACT II - J'ADORE CE QUI ME BRÛLE</h1>
      <h2>Mixed media</h2>
      <br>
      <h4>Opening: Fr. 27.09.24, 18:00</h4>
      <h2>Artist talk: Sa. 12.10.24, 14:00</h2>
      <br>
      <h2>28.09.24 - 18.10.24</h2>
      <h2>Visits by appointment: mail@fomoartspace.ch</h2>
      <br>
      <img src="../assets/img/upcoming_10.jpg" alt="" class="contentImgBig">
      <br>
      <h4>Schellinger Zaugg have an obsessive dream. This dream keeps them up at night. This dream is so intense that they decided to dedicate a multileveled body of work to it.</h4>
      <br>
      <h4>The protagonist is an empty house on the banks of the Limmat. “Zur kleinen Zinne” is owned by a rich and influential Zurich family. All attempts to try to get in contact have gone unanswered. But the artists are not deterred.</h4>
      <br>
      <h4>Instead, they keep digging through information from public archives and databases, gathering objects that surround the house. They are confronted with questions of ownership and moral duty. Suddenly, Max Frisch appears as well.</h4>
      <br>
      <h4>Witness Act II of The Dream and see if you too can be infatuated.</h4>
      <br>
      <h2>Schellinger Zaugg work with concepts of public space, architecture and ownership. These topics are built up through research in different fields, such as urban planning, philosophy or literature. The research is expanded by their own observations gathered on walks or by site visits. The works take the form of site-specific installations, borrowed objects or walking tours.</h2>
      <br>
      <h2>Schellinger Zaugg is Stefan Schellinger (1995) and Samantha Zaugg (1994). They've been working as an artistic duo since 2022. Both attended the Fine Arts Bachelor and Master's program at the Zurich University of the Arts. Currently, they live and work in Zurich, where they run the project space Hotel Tiger. Together they are 348 centimetres tall.</h2>
      <a href="https://schellingerzaugg.ch/" target="_blank"><h5 class="underline">schellingerzaugg.ch/</h5></a>
      <a href="https://www.instagram.com/frauzaugg/" target="_blank"><h5 class="underline">Schellinger Zaugg on Instagram</h5></a>
    </div>
  </div>
</template>
<script>
  export default { 
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
</style>
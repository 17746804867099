<template>
  <div class="mainArea">
    <div class="contentCenter">
      <p>The FOMO Art Space curatorial approach is based on the belief that contemporary art can serve not only as a visual experience, but also as a catalyst for social interaction and community movement. FOMO is designed as a lively, interactive space in which art cannot only be viewed, but actively experienced. We invite the audience to question traditional art concepts through active participation.</p>
      <br>
      <p>Our exhibitions function as an experimental field in which the barriers between artists, artworks and viewers dissolve. A dynamic, relational space is created that sees art as a way of opening up possibilities in exchange and in shaping communities.</p>
      <br>
      <p>The annual program is characterized by five focus exhibitions, each of which has a specific subject. Between these highlights, solo and double exhibitions ensure continued diversity and dynamics.</p>
      <br>
      <p>FOMO Art Space is a registered association based in Zurich, founded and managed by Leandra Agazzi.</p>
    </div>
  </div>
</template>
<script>
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
</style>
<template>
  <div class="mainArea">
    <div class="contentCenter">
      <iframe src="https://fomoartspace.us9.list-manage.com/subscribe?u=3b2f5442cbddae2b2f1ce96ac&id=dc0fd732de" style="border:0;width:100%;" height="651"></iframe>
    </div>
    </div>
</template>
<script>
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
   //  created() {
    
   //  window.open('https://eepurl.com/ip13ls', '_blank');
  //   window.location.href = "/" ;
   // },
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
</style>